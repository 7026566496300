// eslint-disable-next-line import/no-unresolved, import/extensions
import Monitoring from '@/assets/icons/home/Monitoring.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import Inspected from '@/assets/icons/home/Inspected.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import { parseTime, formatTime } from '@/utils'
import router from '@/router'
import { delTower, updateTowerStatus } from '@/api/tower-manage'
// import common from '@/common'
import EditIcon from '../../../assets/icons/tower-manage/edit.png'
import uploadFileIcon from '../../../assets/icons/tower-manage/upload-file.png'
import DeleteIcon from '../../../assets/icons/tower-manage/delete.png'
import Camera from '../../../assets/icons/tower-manage/camera.png'

export const listViewHeaders = that => ([
  {
    label: 'Tower ID',
    prop: 'id',
    property: {
      sortable: true
    },
    render: (h, { row }) => {
      return h('div', { class: '_flex _flex-align__center' }, [
        h('el-image', {
          style: 'width:  50px; height: 50px; border-radius: 5px; margin-right: 10px',
          props: {
            fit: 'cover',
            src: row.imgUrl,
            previewSrcList: [row.imgUrl]
          }
        }),
        row.code
      ])
    }
  },
  {
    label: 'Project Name',
    prop: 'name',
    property: {
      sortable: true
    }
  },
  {
    label: 'Category',
    prop: 'catagories',
    property: {
      width: '300px',
      sortable: true
    },
    render: (h, { row }) => {
      const imgVNodes = []
      if ([0, 2].includes(row.catagories)) {
        imgVNodes.push(h('img', { style: 'margin-right: 10px', attrs: { src: Inspected } }))
        imgVNodes.push('Inspection')
      }
      if ([1, 2].includes(row.catagories)) {
        imgVNodes.push(
          h('img', {
            style: imgVNodes.length > 0 ? 'margin: 0 10px' : 'margin-right: 10px',
            attrs: { src: Monitoring }
          })
        )
        imgVNodes.push('Sensor Monitoring')
      }

      return h('div', { class: '_flex _flex-align__center' }, imgVNodes)
    }
  },
  {
    label: 'Location',
    prop: 'location'
  },
  {
    label: 'Created',
    prop: 'createdTime',
    property: {
      sortable: true
    },
    formatter: (row) => `${parseTime(row.createdTime, 'reg')}`
  },
  {
    label: 'Last Modified',
    prop: 'updateTime',
    property: {
      sortable: true
    },
    formatter: (row) => `${formatTime(Number(row.updateTime))}`
  },
  {
    label: 'Action',
    prop: 'createdTime',
    render: (h, { row }) => {
      return h('div', { class: '_flex _flex-align__center' }, [
        h('el-image', {
          style: 'width:  20px; height: 20px; cursor: pointer; margin-right: 13px',
          props: {
            fit: 'cover',
            src: EditIcon,
          },
          on: {
            click: () => {
              that.editForm(row)
            }
          }
        }),
        h('el-image', {
          style: 'width:  20px; height: 20px; cursor: pointer; margin-right: 13px',
          props: {
            fit: 'cover',
            src: uploadFileIcon,
          },
          on: {
            click: () => {
              router.push({
                path: '/upload-data',
                query: {
                  code: row.id,
                  towerCode: row.code
                }
              })
            }
          }
        }),
        h('el-image', {
          style: 'width:  20px; height: 20px; cursor: pointer; margin-right: 13px',
          props: {
            fit: 'cover',
            src: Camera,
          },
          on: {
            click: () => {
              router.push({
                path: '/tower-camera-manage',
                query: {
                  towerCode: row.code
                }
              })
            }
          }
        }),
        h('el-image', {
          style: 'width:  20px; height: 20px; cursor: pointer; margin-right: 13px',
          props: {
            fit: 'cover',
            src: DeleteIcon,
          },
          on: {
            click: () => {
              console.log('delete')
              that.$confirm(`Do you want to delete the data of ${row.name}`, 'Tips', {}).then(() => {
                delTower({id: row.id}).then((res) => {
                  that.$emit('refresh')
                })
              })
            }
          }
        })
      ])
    }
  },
  {
    label: 'Set as active status',
    prop: 'createdTime',
    render: (h, { row }) => {
      return h('div', { class: '_flex _flex-align__center' }, [
        h('el-switch', {
          props: {
            value: row.status,
            activeValue: 1,
            inactiveValue: 0
          },
          on: {
            change: (val) => {
              console.log('Switch changed:', val);
              // 更新状态
              row.status = val
              // 可以在这里执行其他逻辑
              updateTowerStatus(row.id, val).then((res) => {
                that.$emit('refresh')
              })
            }
          }
        })
      ])
    }
  }
])


