<template>
  <div class="tableBarBox">
    <el-table
      :class="['table-components', options.className]"
      v-loading="options.loading"
      :data="data"
      ref="multipleTable"
      :load="load"
      :height="(options.property && options.property.height) || tableHeight"
      :default-expand-all="options.expandAll"
      @selection-change="handleSelectionChange"
      @current-change="handleCurrentChange"
      @sort-change="sortChange"
      @row-click="toggleSelection"
      @row-dblclick="rowDblClick"
      v-bind="{ ...options.property, ...property }"
    >
      <template #empty>
        <div class="empty-image-box">
          <img style="width: 100px" :src="notData" fit="cover" />
          <span>No Data</span>
        </div>
      </template>
      <el-table-column
        v-if="options.mutiSelect"
        :selectable="options.selectable ? options.selectable : () => true"
        type="selection"
        width="50"
        align="center"
      />
      <el-table-column
        v-if="options.tableIndex"
        label="序号"
        type="index"
        width="50"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-for="column in columnList"
        :key="column.label"
        :label="column.label"
        :prop="column.prop"
        v-bind="column.property"
      >
        <template #default="scope">
          <div @click="typeof column.click === 'function' && column.click(scope.row, scope.$index)">
            <!-- 不为render类型时 -->
            <template v-if="!column.render">
              <span v-if="column.formatter" v-html="column.formatter(scope.row, column)"></span>
              <slot
                v-else-if="column.type == 'slot'"
                :name="column.slotName"
                :row="scope.row"
                :index="scope.$index"
              ></slot>
              <span v-else> {{ scope.row[column.prop] }} </span>
            </template>
            <expand-dom
              v-else
              :column="column"
              :row="scope.row"
              :render="column.render"
              :index="scope.$index"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        class-name="small-padding fixed-width"
        :min-width="(operates && operates.width) || 200"
        v-if="
          operates &&
          operates.list &&
          (operates && operates.show == undefined ? true : operates.show)
        "
      >
        <template #default="scope">
          <div class="operate-group">
            <template v-for="item in Array.isArray(operateList[0]) ? operateList[0] : operateList">
              <el-button
                :key="item.label"
                size="medium"
                :type="item.type"
                :icon="item.icon"
                @click.stop="item.click(scope.row, scope.$index)"
                v-if="typeof item.show === 'function' ? item.show(scope.row) : item.show || false"
                :disabled="typeof item.disabled === 'undefined' ? false : item.disabled(scope.row)"
              >
                {{ item.label }}
              </el-button>
            </template>

            <el-dropdown
              v-if="
                operateList[1] &&
                operateList[1].length &&
                operateList[1].filter((btn) =>
                  typeof btn.show === 'function' ? btn.show(scope.row) : btn.show || false
                ).length > 0
              "
            >
              <el-button size="medium" :type="operateList[0][0].type">
                &nbsp;| 更多
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="btn in operateList[1]">
                  <el-dropdown-item
                    :key="btn.label"
                    v-if="typeof btn.show === 'function' ? btn.show(scope.row) : btn.show || false"
                  >
                    <el-button
                      size="medium"
                      :type="btn.type"
                      :icon="btn.icon"
                      @click.stop="btn.click(scope.row, scope.$index)"
                      :disabled="
                        typeof btn.disabled === 'undefined' ? false : btn.disabled(scope.row)
                      "
                    >
                      {{ btn.label }}
                    </el-button>
                  </el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import notData from '@/assets/not-data.png'
import { props, expandDom, property } from './props'

export default {
  name: 'PowerFulTable',
  // 组件
  components: {
    expandDom
  },
  data() {
    return {
      property,
      notData,
      tableHeight: 'auto',
      columnList: [],
      operateList: [],
      selection: []
    }
  },
  props,
  mounted() {
    this.columnList = this.columns
    this.$nextTick(() => {
      // 延时等待搜索栏高度计算完成
      setTimeout(() => {
        this.computedHeader()
      }, 200)
    })
    window.addEventListener('resize', this.computedHeader)
  },
  watch: {
    columns: {
      deep: true,
      handler(newVal) {
        // console.log(newVal)
        this.columnList = newVal.filter((i) => i.show === true)
        setTimeout(() => {
          if (this.$refs.multipleTable) this.$refs.multipleTable.doLayout()
        }, 60)
      }
    },
    operates: {
      immediate: true,
      deep: true,
      handler(val) {
        // 拆分list
        if (val !== undefined && val != null) this.operateList = val.list
      }
    }
  },
  methods: {
    // height 为searchForm 组件内传递
    computedHeader(height) {
      if (typeof height === 'number') {
        this.tableHeight += height
        return
      }
      // console.log(window.innerHeight, document.getElementsByClassName(this.options.className || 'table-components')[0].getBoundingClientRect().top)
      if (document.getElementsByClassName(this.options.className || 'table-components').length) {
        // 30为底部空白 (+ || -)实际情况所偏差
        this.tableHeight =
          window.innerHeight -
          document
            .getElementsByClassName(this.options.className || 'table-components')[0]
            .getBoundingClientRect().top -
          30 -
          this.surplusHeight
      }
    },

    sortChange(obj) {
      if (obj.column) {
        // 如果是远程排序 则交给父组件自己去处理 使用自定义事件暴露到组件上
        if (obj.column.sortable === 'custom') {
          this.$emit('sortCustom', obj)
        }
      }
    },
    // 点击行选中
    toggleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
      this.$emit('rowClick', row)
    },
    rowDblClick(selection) {
      this.$emit('rowDblClick', selection)
    },
    handleSelectionChange(selection) {
      this.selection = selection
      this.$emit('handleSelectionChange', selection)
    },
    handleCurrentChange(row) {
      this.$emit('handleCurrentChange', row)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
  color: var(--color-black);
  font-size: 12px;
  font-weight: 600;
  &::before {
    background-color: rgba(0, 0, 0, 0);
  }

  .cell {
    padding-left: 20px !important;
  }

  &__body {
    background: inherit;

    tr {
      background: inherit;

      &.current-row {
        > td {
          background: inherit;
          > .cell {
            background: var(--color-primary-light-9);
          }
        }
      }

      &:hover .cell {
        background: var(--color-info-light-9);
      }
      &:hover td {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
  }
  &__row {
    td:first-of-type > .cell {
      border-radius: 5px 0 0 5px;
    }
    td:last-of-type > .cell {
      border-radius: 0 5px 5px 0;
    }
    .cell {
      padding-top: 20px;
      padding-bottom: 20px;
      background: inherit;
      transition: all 0.3s;
    }
  }

  .small-padding .cell {
    padding: 13.5px 0;
  }

  .el-table__header-wrapper {
    border-radius: 5px;
    th.el-table__cell {
      background: inherit;
    }
  }

  .sort-caret.ascending {
    border-bottom-color: var(--color-info);
  }
  .ascending .sort-caret.ascending {
    border-bottom-color: var(--color-primary);
  }
  .sort-caret.descending {
    border-top-color: var(--color-info);
  }
  .descending .sort-caret.descending {
    border-top-color: var(--color-primary);
  }
}

.empty-image-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    width: 100%;
    text-align: center;
  }
}
</style>
