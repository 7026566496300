<template>
  <div>
    <PowerFulTable
      :columns="columns"
      :data="list"
      :options="options"
      class="list-view-table"
      ref="table"
    />

    <el-pagination
      v-if="Number(total) !== 0"
      background
      layout="->, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved, import/extensions
import PowerFulTable from '@/components/table/powerful-table.vue'
import { listViewHeaders } from './index-data'

export default {
  components: {
    PowerFulTable
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },

    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: {
        property: { highlightCurrentRow: true }
      },
      columns: listViewHeaders(this),
      listQuery: {
        pageNo: ''
      }
    }
  },
  methods: {
    editForm(row) {
      this.$emit('edit', row)
    },

    handleCurrentChange(e) {
      this.listQuery.pageNo = e
      this.$emit('refresh', this.listQuery.pageNo)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-view-table {
  ::v-deep .el-table__row .cell {
    height: 90px;
    line-height: 50px;
  }
}
</style>
