export const expandDom = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  render: (h, ctx) => {
    const params = {
      row: ctx.props.row,
      index: ctx.props.index
    }
    if (ctx.props.column) params.column = ctx.props.column
    return ctx.props.render(h, params, ctx)
  }
}

export const props = {
  columns: {
    type: Array,
    required: true,
    default: () => [] // es6的箭头函数
  },
  data: {
    type: Array,
    required: true
  },
  options: {
    type: Object,
    default: () => ({})
  },
  operates: {
    type: Object,
    default: () => ({})
  },
  // 懒加载数据
  load: Function,

  surplusHeight: {
    type: Number,
    default: 0
  }
}

export const property = {
  // 表头行样式
  headerRowStyle: {
    background: '#E1E1E1',
    color: 'var(--color-black)',
    fontSize: '12px'
  },
  cellStyle: {
    padding: '20px 0 0 0'
  }
}
