import request from '@/utils/service'


// 分页查询电塔
export function findPage(data) {
  return request({
    url: '/tower/adminFindPage',
    data
  })
}

// 新增电塔
export function addTower(data) {
  return request({
    url: '/tower/add',
    data
  })
}

// 编辑电塔
export function editTower(data) {
  return request({
    url: '/tower/update',
    data
  })
}

// 删除电塔
export function delTower(data) {
  return request({
    url: '/tower/delete',
    data
  })
}

// 更改电塔状态
export function updateTowerStatus(id, status) {
  return request({
    url: `/tower/updateStatus/${id}/${status}`,
    data: {}
  })
}


// 保存电塔缺陷信息
export function saveTowerDefectReportDate(data) {
  return request({
    url: '/tower/alert/saveTowerDefectReportDate',
    data
  })
}

// 电塔缺陷信息列表
export function towerDefectReportDateList(data) {
  return request({
    url: '/tower/alert/towerDefectReportDateList',
    data
  })
}
// 删除电塔缺陷信息
export function deleteTowerDefectReportDate(data) {
  return request({
    url: '/tower/alert/deleteTowerDefectReportDate',
    data
  })
}


// 相机信息管理--------------

// 相机信息列表
export function getCameras(data) {
  return request({
    url: '/tower/cameraInfo/list',
    params: data
  })
}
// 新增相机
export function addCamera(data) {
  return request({
    url: '/tower/cameraInfo/saveOrUpdate',
    data
  })
}